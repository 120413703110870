import { getMobiscrollDisplayText } from '@catalytic-health-partners/common-components';
import { MbscCalendarEventData } from '@mobiscroll/react';
import { Repeat } from '@mui/icons-material';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    List,
    ListItem,
    Stack,
    Typography,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import { Form, Formik, FormikProps } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RRule } from 'rrule';
import * as Yup from 'yup';
import { ProviderTypeEnum } from '../../../Enum/ProviderTypeEnum';
import { Provider } from '../../../Models/Provider/Provider.model';
import IProviderSelectOption from '../../../Models/Provider/ProviderSelectOption.model';
import Appointment from '../../../Models/Scheduling/Appointment.model';
import { OverbookResult } from '../../../Models/Scheduling/OverbookResult.model';
import { setShowMessage } from '../../../store/shared.slice';
import { getHumanizedTimeSpan, getTimeSpan } from '../../../utils/common';
import { ERROR, apiString, appointmentStatusEnums, appointmentTypeEnums, meansOfEngagementEnums, providerTypes } from '../../../utils/constants';
import FormikAutocompleteControlled from '../../FormikFields/FormikAutocompleteControlled';
import FormikDesktopDateTimePicker from '../../FormikFields/FormikDesktopDateTimePicker';
import FormikTextField from '../../FormikFields/FormikTextField';
import { IBasicModalProps } from '../../interfaces/IBasicModalProps';
import HelpDocumentIconButton from '../HelpDocumentIconButton';
import ConfirmPastEventCreateModal from './ConfirmPastEventCreateModal';
import { createEvent, getEventDuration, getProvidersOverbookStatusForDates } from '../Services/CommonCalendarServices';
import { CalendarEventData } from '../types/CalendarEventData';
import RecurrenceEditorModal from './RecurrenceEditorModal';
import RecurrenceUpdateStrategyModal from './RecurrenceUpdateStrategyModal';
import { IOption } from '../../interfaces/IOption';
import { fetchMemberDefaultAddress } from '../../../store/calendar.slice';
import { RootState } from '../../../reducers';

// TODO: network calls should trigger a backdrop which prevents further
// user action until the network action is completed to prevent data crashes and/or race conditions.

// TODO: Make it so that when the user changes date it validates the time ranges so that the ending date time
// cannot be before the starting date time and vice versa.

const validationSchema = Yup.object({
    appointmentType: Yup.number().required('Appointment type is required.'),
    meansOfEngagement: Yup.number().required('Means of engagement is required.'),
    status: Yup.number().required('Appointment status is required.'),
    ProviderType: Yup.number().required('Provider type is required.'),
    ProviderName: Yup.string().when('ProviderType', {
        is: (val) => val === ProviderTypeEnum.External,
        then: Yup.string().required('A provider name is required.'),
    }),
    ProviderIds: Yup.array().when('ProviderType', {
        is: (val) => val === ProviderTypeEnum.Internal,
        then: Yup.array().min(1, 'At least one provider is required.').required('A provider is required.'),
    }),
    startDate: Yup.date()
        .required('A starting date is required.')
        .test('is-before-end-date', 'Start date must be before end date', function (value) {
            const { endDate } = this.parent;
            return !value || !endDate || value < endDate;
        }),
    endDate: Yup.date()
        .required('An ending date is required.')
        .test('is-after-start-date', 'End date must be after start date', function (value) {
            const { startDate } = this.parent;
            return !value || !startDate || value > startDate;
        }),
});

const INITIAL_RECURRENCE_TITLE = 'NON-RECURRING APPOINTMENT';

type ErrorAlertType = {
    errorTitle: string;
    errorMessage: string;
};

export interface ICalendarEventEditModalProps extends IBasicModalProps {
    eventData: CalendarEventData;
    memberId: string;
    onUpdateEventSuccess: (eventDate: Appointment) => void;
    onCreateEventSuccess: (eventDate: Appointment, rescheduledEvent?: CalendarEventData) => void;
    onRescheduleEvent: (eventDate: CalendarEventData) => void;
    setLoadingOverlay: (isLoading: boolean) => void;
    eventList: MbscCalendarEventData[];
    canCreate?: boolean;
    canEdit?: boolean;
    allowAllProviderOption?: boolean;
}

const CalendarEventEditModal = (props: ICalendarEventEditModalProps) => {
    const {
        open,
        onClose,
        onSuccess,
        eventData,
        memberId,
        onUpdateEventSuccess,
        onCreateEventSuccess,
        setLoadingOverlay,
        eventList,
        canCreate = true,
        canEdit = true,
        allowAllProviderOption = false,
    } = props;
    const { start } = eventData;
    const dispatch = useDispatch();
    const { defaultAddress } = useSelector((state: RootState) => state.calendar);
    const [providerInputValue, setProviderInputValue] = useState<string>('');
    const [providerOptions, setProviderOptions] = useState<IProviderSelectOption[]>([]);
    const [selectedProviderOptions, setSelectedProviderOptions] = useState<IProviderSelectOption[]>([]);
    const [isRecurrenceEditorOpen, setIsRecurrenceEditorOpen] = useState<boolean>(false);
    const [isRecurringUpdateStrategyOpen, setIsRecurringUpdateStrategyOpen] = useState<boolean>(false);
    const [isConfirmPastEventCreateOpen, setIsConfirmPastEventCreateOpen] = useState<boolean>(false);
    const [isFetchingOverbooks, setIsFetchingOverbooks] = useState<boolean>(false);
    const [overbookResults, setOverbookResults] = useState<OverbookResult[]>([]);
    const overbookMessage: string | null = useMemo(() => {
        if (overbookResults.length === 0) return null;

        const overbookedProviders = overbookResults.filter((result) => result.IsOverbooked);
        const overbookedProvidersWithName = overbookedProviders.map((result) => {
            const name = providerOptions.find((provider) => provider.ProviderId === result.ProviderId).ProviderName;
            return { ...result, ProviderName: name };
        });
        if (overbookedProviders.length === 0) return null;

        const providerNames = overbookedProvidersWithName.map((provider) => provider.ProviderName).join(' - ');
        return `The following providers would be overbooked: ${providerNames}`;
    }, [overbookResults, providerOptions]);

    const updateOverbookAlert = useCallback((providerIds: string[], startDate: Date, endDate: Date, appointmentId: string) => {
        setIsFetchingOverbooks(true);
        if (startDate === null || endDate === null || appointmentId === null || providerIds === null || providerIds.length === 0) {
            setOverbookResults([]);
            setIsFetchingOverbooks(false);
            return;
        }
        getProvidersOverbookStatusForDates(providerIds, startDate as Date, endDate as Date, appointmentId)
            .then((response) => {
                setOverbookResults(response.data);
            })
            .finally(() => {
                setIsFetchingOverbooks(false);
            });
    }, []);

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [eventData.ProviderIds, eventData.start, eventData.end, eventData.Id, open]);

    console.log({ overbookResults, isFetchingOverbooks });

    const [errorAlert, setErrorAlert] = useState<ErrorAlertType | null>(null);

    const allowEdits = useMemo(() => {
        if (!allowAllProviderOption && eventData.ProviderType === ProviderTypeEnum.AllInternal) {
            return false;
        }
        return canCreate || canEdit;
    }, [canCreate, canEdit, allowAllProviderOption, eventData?.ProviderType]);

    const hidingSave: boolean = useMemo(() => {
        if (!allowAllProviderOption && eventData.ProviderType === ProviderTypeEnum.AllInternal) {
            return true;
        }
        if (canCreate && allowEdits) {
            return false;
        } else if (!allowEdits && !canCreate) {
            return true;
        } else if (!allowEdits && canCreate) {
            return !eventData?.Id;
        } else if (!canCreate && allowEdits) {
            return eventData?.Id === undefined;
        } else {
            return true;
        }
    }, [allowAllProviderOption, eventData.ProviderType, eventData?.Id, canCreate, allowEdits]);

    useEffect(() => {
        if (memberId && memberId !== '') {
            dispatch(fetchMemberDefaultAddress(memberId));
        }
    }, [dispatch, memberId]);

    useEffect(() => {
        if (open) {
            updateOverbookAlert(
                selectedProviderOptions.map((item) => item.ProviderId),
                eventData.startDate,
                eventData.endDate,
                eventData.Id
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, updateOverbookAlert]);

    const appointmentTypeOptions = useMemo(() => {
        return Object.values(appointmentTypeEnums).map((type) => ({ value: type.value, label: type.text }));
    }, []);

    const meansOfEngagementOptions = useMemo(() => {
        return Object.values(meansOfEngagementEnums).map((type) => ({ value: type.value, label: type.text }));
    }, []);

    const appointmentStatusOptions = useMemo(() => {
        return Object.values(appointmentStatusEnums).map((type) => ({ value: type.value, label: type.text, color: type.eventColor }));
    }, []);

    const providerTypeOptions: IOption<number>[] = useMemo(() => {
        const options = Object.values(providerTypes).map((type) => ({ value: type.value, label: type.text }));
        if (allowAllProviderOption || eventData?.ProviderType === ProviderTypeEnum.AllInternal) {
            options.unshift({ value: ProviderTypeEnum.AllInternal, label: 'All Internal Providers' });
        }
        return options;
    }, [allowAllProviderOption, eventData?.ProviderType]);

    const editorTitle = useMemo(() => {
        if (eventData?.IsRescheduling) {
            return 'Reschedule Appointment';
        } else if (eventData?.Id) {
            return !hidingSave ? 'Edit Appointment' : 'View Appointment Details';
        } else {
            return !hidingSave ? 'Create Appointment' : 'View Appointment Details';
        }
    }, [hidingSave, eventData?.Id, eventData?.IsRescheduling]);

    const addEvent = async (values: CalendarEventData) => {
        setLoadingOverlay(true);
        const newEvent = {
            ...values,
            id: 'mbsc_',
            IsTemplate: false,
            MemberObjectId: eventData.MemberObjectId ?? memberId ?? null,
        };

        try {
            const response = await createEvent(newEvent);
            if (response.status === 200) {
                if (values.IsRescheduling) {
                    eventData.RescheduledAppointmentId = response.data.Id;
                    eventData.Id = response.data.OriginalAppointmentId;
                    onCreateEventSuccess(response.data, eventData);
                } else {
                    onCreateEventSuccess(response.data);
                }
                onSuccess();
            } else {
                throw new Error('Error creating event');
            }
        } catch (error) {
            let errorMessage: ErrorAlertType = { errorTitle: 'Error', errorMessage: error };
            if (error?.response?.data?.error) {
                errorMessage = { errorTitle: 'Error', errorMessage: error.response.data.error };
            }
            console.error(error);
            setErrorAlert(errorMessage);
            dispatch(setShowMessage(true, errorMessage.errorMessage, ERROR));
            setLoadingOverlay(false);

            throw error;
        }
        setLoadingOverlay(false);
    };

    const updateEvent = async (values: CalendarEventData) => {
        setLoadingOverlay(true);
        try {
            const newEvent = { ...values, id: values.Id };
            const response = await axios.put<Appointment>(`${apiString}/Scheduling/Update`, newEvent);
            if (response.status === 200) {
                onUpdateEventSuccess(response.data);
                onSuccess();
            }
        } catch (error) {
            let errorMessage: ErrorAlertType = { errorTitle: ERROR, errorMessage: error };
            if (error?.response?.data?.error) {
                errorMessage = { errorTitle: error.message, errorMessage: error.response.data.error };
            }
            console.error(error);
            setErrorAlert(errorMessage);
        }

        setLoadingOverlay(false);
    };

    const combineWithCurrentTime = (baseDate: Date): Date => {
        const now = new Date();
        // Set the time from current local time to the baseDate
        baseDate.setHours(now.getHours(), now.getMinutes(), now.getSeconds(), now.getMilliseconds());
        return baseDate;
    };

    const getClosestHalfHour = (baseDate: Date): Date => {
        baseDate = combineWithCurrentTime(baseDate);

        const minutes = baseDate.getMinutes();

        // If the minutes are between 0 and 30, we set the minutes to 30. Otherwise, we roll to the next hour.
        if (minutes <= 30) {
            baseDate.setMinutes(30);
        } else {
            baseDate.setHours(baseDate.getHours() + 1);
            baseDate.setMinutes(0);
        }
        baseDate.setSeconds(0);
        baseDate.setMilliseconds(0);

        return baseDate;
    };

    const getClosestHalfHourPlusThirty = (baseDate: Date): Date => {
        baseDate = getClosestHalfHour(baseDate);
        baseDate.setMinutes(baseDate.getMinutes() + 30); // Add 30 minutes
        return baseDate;
    };

    const getDuration = useCallback((appointmentType: number, providerRoles: string[]) => getEventDuration(appointmentType, providerRoles), []);

    useEffect(() => {
        axios.get(`${apiString}/Provider/getactiveproviders`).then((response) => {
            if (response.status === 200) {
                const responseOptions = response.data.map((provider: Provider) => ({
                    ProviderId: provider.Id,
                    ProviderName: `${provider.LastName}, ${provider.FirstName}`,
                    Role: provider.Role.RoleName,
                }));
                setProviderOptions(responseOptions);
            }
        });
    }, []);

    useEffect(() => {
        if (eventData?.ProviderIds?.length === 0 || eventData?.ProviderIds === undefined) {
            setSelectedProviderOptions([]);
        } else if (providerOptions?.length > 0 && eventData?.ProviderIds?.length > 0) {
            const selectedProviders = providerOptions.filter((provider) => eventData.ProviderIds.includes(provider.ProviderId));

            setSelectedProviderOptions(selectedProviders);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerOptions, eventData.ProviderIds, open]);

    const handleCancel = useCallback(() => {
        setSelectedProviderOptions([]);
        setErrorAlert(null);
        setOverbookResults([]);
        onClose();
    }, [setSelectedProviderOptions, onClose]);

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Formik
                validationSchema={validationSchema}
                enableReinitialize={true}
                initialValues={
                    {
                        ...eventData,
                        ProviderType: eventData.ProviderType ?? providerTypeOptions.find((x) => x.value === ProviderTypeEnum.Internal)?.value,
                        ProviderIds: eventData.ProviderIds ?? [],
                        ProviderName: eventData.ProviderName ?? '',
                        address: defaultAddress ?? eventData.address ?? '',
                        status: eventData.status ?? 0,
                        IsUsingDefaultDuration: eventData.IsUsingDefaultDuration === true ? true : eventData.Id ? false : true,
                        startDate: eventData.startDate ? (eventData.start as Date) : getClosestHalfHour(new Date(start)),
                        endDate: eventData.endDate ? (eventData.end as Date) : getClosestHalfHourPlusThirty(new Date(start)),
                    } as CalendarEventData
                }
                onSubmit={async (values: CalendarEventData, { setSubmitting }) => {
                    setSubmitting(true);
                    setErrorAlert(null);
                    if (!values.Id) {
                        await addEvent(values);
                    } else {
                        if (values.recurring?.length > 0) {
                            setIsRecurringUpdateStrategyOpen(true);
                        } else {
                            await updateEvent(values);
                        }
                    }
                    setSubmitting(false);
                    // We will have to provide mapping for the appointment here as there are many extra fields that are not needed
                }}
            >
                {({
                    submitForm,
                    values,
                    setFieldValue,
                    isValid,
                    resetForm,
                    errors,
                    handleChange,
                    validateForm,
                    setFieldTouched,
                    isSubmitting,
                }: FormikProps<CalendarEventData>) => (
                    <Form>
                        <Dialog
                            open={open}
                            disableEscapeKeyDown
                            onClose={(event, reason) => {
                                if (reason && reason === 'backdropClick') return;
                                onClose();
                            }}
                        >
                            <DialogTitle>
                                <Grid container spacing={1} justifyContent="flex-end">
                                    <Grid container alignItems="center" item lg={12} md={12} sm={12} xs={12} style={{ justifyContent: 'space-between' }}>
                                        {editorTitle}
                                        <div style={{ float: 'right' }}>
                                            <HelpDocumentIconButton />
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        hidden={
                                            errorAlert !== null ||
                                            values.startDate === null ||
                                            values.endDate === null ||
                                            getTimeSpan(values.startDate, values.endDate).asHours() <= 1
                                        }
                                    >
                                        <Alert variant="filled" severity="info">
                                            This event will last about {getHumanizedTimeSpan(values.startDate, values.endDate)}
                                        </Alert>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} hidden={errorAlert === null}>
                                        <Alert variant="filled" severity="error" onClose={() => setErrorAlert(null)}>
                                            <AlertTitle>{errorAlert?.errorTitle ?? 'Error'}</AlertTitle>
                                            {errorAlert?.errorMessage ?? 'An error occurred. Please try again.'}
                                        </Alert>
                                    </Grid>
                                </Grid>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container spacing={2} style={{ marginTop: 10 }}>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <FormikAutocompleteControlled
                                            disabled={hidingSave}
                                            multiple={false}
                                            name="appointmentType"
                                            label="Type of Appointment"
                                            disableClearable
                                            required
                                            options={appointmentTypeOptions}
                                            getOptionLabel={(option: any) => {
                                                return option.label;
                                            }}
                                            value={appointmentTypeOptions.find((option) => option.value === values.appointmentType) ?? null}
                                            onChange={(e, value) => {
                                                if (!values.IsUsingDefaultDuration) {
                                                    setFieldValue('appointmentType', value.value);
                                                    return;
                                                }
                                                if (value?.value) {
                                                    let duration = getDuration(
                                                        value.value,
                                                        selectedProviderOptions.map((provider) => provider.Role)
                                                    );
                                                    setFieldValue('appointmentType', value.value);

                                                    if (!values.Id || values.Id === '_mbsc') {
                                                        const newEndDate = new Date(values.startDate);
                                                        newEndDate.setMinutes(newEndDate.getMinutes() + duration);
                                                        setFieldValue('endDate', newEndDate);
                                                        validateForm();
                                                    }
                                                }
                                            }}
                                            isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <FormikAutocompleteControlled
                                            disabled={hidingSave}
                                            multiple={false}
                                            name="meansOfEngagement"
                                            label="Means of Engagement"
                                            required
                                            disableClearable
                                            options={meansOfEngagementOptions}
                                            getOptionLabel={(option: any) => {
                                                return option.label;
                                            }}
                                            value={meansOfEngagementOptions.find((option) => option.value === values.meansOfEngagement) ?? null}
                                            onChange={(e, value) => {
                                                setFieldValue('meansOfEngagement', value.value);
                                            }}
                                            isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                                        />
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormikAutocompleteControlled
                                            disabled={hidingSave}
                                            multiple={false}
                                            name="status"
                                            label="Appointment Status"
                                            required
                                            disableClearable
                                            options={appointmentStatusOptions}
                                            renderOption={(props, option) => (
                                                <Box component="li" {...props}>
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            width: 14,
                                                            height: 14,
                                                            marginRight: 1,
                                                            backgroundColor: option.color,
                                                            display: 'inline-block',
                                                            borderRadius: '50%',
                                                        }}
                                                    />
                                                    {option.label}
                                                </Box>
                                            )}
                                            getOptionLabel={(option: any) => {
                                                return option.label;
                                            }}
                                            value={appointmentStatusOptions.find((option) => option.value === values.status) ?? null}
                                            onChange={(e, value) => {
                                                if (value?.value !== undefined && value?.value !== null) {
                                                    setFieldValue('status', value.value);
                                                }
                                            }}
                                            isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                                        />
                                    </Grid>
                                    <Grid container item>
                                        <Grid item lg={12} md={12} sm={12} xs={12}>
                                            <FormControlLabel
                                                disabled={hidingSave}
                                                control={
                                                    <Checkbox
                                                        checked={values.IsCustomAddress}
                                                        onChange={(e, value) => {
                                                            setFieldValue('IsCustomAddress', value);
                                                        }}
                                                        name="IsCustomAddress"
                                                    />
                                                }
                                                label="Use a custom address for this appointment"
                                            />
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} hidden={values.IsCustomAddress}>
                                            <FormikTextField disabled={hidingSave} readOnly={true} name="address" label="Default Address" />
                                        </Grid>
                                        <Grid item lg={12} md={12} sm={12} xs={12} hidden={!values.IsCustomAddress}>
                                            <FormikTextField
                                                disabled={hidingSave}
                                                readOnly={!values.IsCustomAddress}
                                                name="CustomAddress"
                                                label="Custom Address"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormikTextField disabled={hidingSave} name="reason" label="Appointment Note" multiline minRows={3} maxRows={10} />
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormikAutocompleteControlled
                                            disabled={hidingSave}
                                            multiple={false}
                                            name="ProviderType"
                                            label="Internal/External Provider"
                                            required
                                            disableClearable
                                            options={providerTypeOptions}
                                            getOptionLabel={(option: any) => {
                                                return option.label;
                                            }}
                                            value={providerTypeOptions.find((option) => option.value === values.ProviderType) ?? null}
                                            onChange={(e, value) => {
                                                setFieldValue('ProviderType', value.value);
                                            }}
                                            isOptionEqualToValue={(option: any, value: any) => option.value === value.value}
                                        />
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} hidden={values.ProviderType !== ProviderTypeEnum.External}>
                                        <FormikTextField disabled={hidingSave} name="ProviderName" label="External Provider Name" required />
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12} hidden={values.ProviderType !== ProviderTypeEnum.Internal}>
                                        <FormikAutocompleteControlled
                                            multiple={true}
                                            disabled={hidingSave}
                                            fullWidth
                                            loading={isFetchingOverbooks}
                                            id="calendar-edit-provider-selection"
                                            label="Internal Providers"
                                            required
                                            options={providerOptions as IProviderSelectOption[]}
                                            isOptionEqualToValue={(option, value) => {
                                                return option.ProviderId === value.ProviderId;
                                            }}
                                            value={selectedProviderOptions}
                                            onChange={(event, value) => {
                                                const providerValue = value as IProviderSelectOption[];
                                                if (value) {
                                                    setSelectedProviderOptions(providerValue);
                                                    setFieldValue(
                                                        'ProviderIds',
                                                        providerValue.map((item) => item.ProviderId)
                                                    );
                                                } else {
                                                    setSelectedProviderOptions(providerValue);
                                                }

                                                let duration = getDuration(
                                                    values.appointmentType,
                                                    providerValue.map((provider) => provider.Role)
                                                );
                                                if (!values.Id || values.Id === '_mbsc') {
                                                    const newEndDate = new Date(values.startDate);
                                                    newEndDate.setMinutes(newEndDate.getMinutes() + duration);
                                                    setFieldValue('endDate', newEndDate);
                                                }

                                                // This is a hack to force the form to revalidate after the provider selection changes
                                                // Basically we have to wait for the asynchronous set field value to complete before we can revalidate
                                                setTimeout(validateForm, 0);
                                                updateOverbookAlert(
                                                    providerValue.map((item) => item.ProviderId),
                                                    values.startDate,
                                                    values.endDate,
                                                    values.Id
                                                );
                                            }}
                                            inputValue={providerInputValue}
                                            onInputChange={(event, value) => {
                                                setProviderInputValue(value);
                                            }}
                                            getOptionLabel={(option: any) => option.ProviderName}
                                            name={'ProviderIds'}
                                        />
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <FormControlLabel
                                            disabled={hidingSave}
                                            control={
                                                <Checkbox
                                                    checked={values.IsUsingDefaultDuration}
                                                    onChange={(e, value) => {
                                                        setFieldValue('IsUsingDefaultDuration', value);
                                                        if (value) {
                                                            const { appointmentType } = values;
                                                            let duration = getDuration(
                                                                appointmentType,
                                                                selectedProviderOptions.map((provider) => provider.Role)
                                                            );
                                                            const startDate = new Date(values.startDate);
                                                            const endDate = new Date(startDate.getTime() + duration * 60000);
                                                            setFieldValue('endDate', endDate);
                                                            validateForm();
                                                        }
                                                    }}
                                                    name="IsUsingDefaultDuration"
                                                />
                                            }
                                            label={
                                                <Typography variant="body2">
                                                    Automatically set appointment duration based on provider roles and appointment type. (
                                                    {getDuration(
                                                        values.appointmentType,
                                                        selectedProviderOptions.map((provider) => provider.Role)
                                                    )}{' '}
                                                    minutes)
                                                </Typography>
                                            }
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <FormikDesktopDateTimePicker
                                            disabled={hidingSave}
                                            name="startDate"
                                            label="Starting Date and Time"
                                            onChange={(value) => {
                                                if (!values.IsUsingDefaultDuration) {
                                                    setFieldValue('startDate', value);
                                                    updateOverbookAlert(values.ProviderIds, value, values.endDate, values.Id);
                                                    return;
                                                }
                                                setFieldValue('startDate', value);
                                                const { appointmentType } = values;
                                                let duration = getDuration(
                                                    appointmentType,
                                                    selectedProviderOptions.map((provider) => provider.Role)
                                                );
                                                const startDate = new Date(value);
                                                const endDate = new Date(startDate.getTime() + duration * 60000);
                                                setFieldValue('endDate', endDate);
                                                updateOverbookAlert(values.ProviderIds, value, endDate, values.Id);
                                                validateForm();
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={6}>
                                        <FormikDesktopDateTimePicker
                                            disabled={hidingSave || values.IsUsingDefaultDuration}
                                            name="endDate"
                                            label="Ending Date and Time"
                                            onChange={(value) => {
                                                if (!values.IsUsingDefaultDuration) {
                                                    setFieldValue('endDate', value);
                                                    updateOverbookAlert(values.ProviderIds, values.startDate, value, values.Id);
                                                    return;
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <ConfirmPastEventCreateModal
                                            open={isConfirmPastEventCreateOpen}
                                            startDate={values.startDate}
                                            onClose={() => setIsConfirmPastEventCreateOpen(false)}
                                            onSuccess={() => {
                                                setIsConfirmPastEventCreateOpen(false);
                                                submitForm();
                                            }}
                                        />
                                        <RecurrenceUpdateStrategyModal
                                            open={isRecurringUpdateStrategyOpen}
                                            createEvent={addEvent}
                                            updateEvent={updateEvent}
                                            onClose={() => {
                                                setIsRecurringUpdateStrategyOpen(false);
                                            }}
                                            onSuccess={() => {
                                                setIsRecurringUpdateStrategyOpen(false);
                                                onSuccess();
                                            }}
                                            eventList={eventList}
                                        />
                                        <RecurrenceEditorModal
                                            open={isRecurrenceEditorOpen}
                                            onClose={() => setIsRecurrenceEditorOpen(false)}
                                            recurrenceString={values.recurring}
                                            onRecurrenceStringChange={(newRule: string) => {
                                                const newRuleText = RRule.fromString(newRule).toText().toUpperCase();
                                                const oldRuleText = RRule.fromString(values?.recurring ?? '')
                                                    .toText()
                                                    .toUpperCase();
                                                if (newRuleText !== oldRuleText) {
                                                    setFieldValue('recurring', newRule);
                                                }
                                            }}
                                        />
                                        <Button
                                            startIcon={<Repeat />}
                                            variant="outlined"
                                            fullWidth
                                            disabled={hidingSave}
                                            style={{ height: '50px' }}
                                            onClick={() => setIsRecurrenceEditorOpen(true)}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Typography>
                                                    {values?.recurring?.length > 0
                                                        ? getMobiscrollDisplayText(RRule.fromString(values.recurring))
                                                        : INITIAL_RECURRENCE_TITLE}
                                                </Typography>
                                            </Box>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Stack direction="column" alignItems="center" justifyContent={'center'} width="100%" spacing={1}>
                                    {isFetchingOverbooks && (
                                        <Box width="100%" style={{ textAlign: 'center' }}>
                                            <CircularProgress size={20} /> Checking for overbooked providers...
                                        </Box>
                                    )}
                                    {overbookMessage && !isFetchingOverbooks && (
                                        <Box width="100%">
                                            <Alert severity="warning">{overbookMessage}</Alert>
                                        </Box>
                                    )}
                                    {!isValid && (
                                        <Box sx={{ marginTop: '16px', width: '100%' }}>
                                            <Typography variant="caption" color="error">
                                                Please correctly fill in all fields before saving.
                                            </Typography>
                                            <List>
                                                {Object.keys(errors).map((key) => (
                                                    <ListItem sx={{ padding: '0px', margin: '0px' }} key={key}>
                                                        <Typography variant="caption" color="error">
                                                            {`• ${String(errors[key])}`}
                                                        </Typography>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Box>
                                    )}
                                    <Box width="100%" display="flex" justifyContent="flex-end">
                                        <Stack direction="row" spacing={2}>
                                            {!hidingSave && (
                                                <Button
                                                    variant="contained"
                                                    color="success"
                                                    onClick={() => {
                                                        if (values.startDate && values.startDate.getTime() < Date.now()) {
                                                            setIsConfirmPastEventCreateOpen(true);
                                                        } else {
                                                            submitForm();
                                                        }
                                                    }}
                                                    disabled={isSubmitting || Object.keys(errors).length > 0}
                                                >
                                                    Save
                                                </Button>
                                            )}
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    resetForm();
                                                    handleCancel();
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </DialogActions>
                        </Dialog>
                    </Form>
                )}
            </Formik>
        </LocalizationProvider>
    );
};

export default CalendarEventEditModal;
