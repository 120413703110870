import {
    Avatar,
    Box,
    Button,
    Chip,
    Divider,
    FormControl,
    FormGroup,
    Grid,
    Input,
    InputLabel,
    MenuItem,
    Modal,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { EncounterStatusEnum } from '../../Enum/EncounterStatusEnum';
import { RootState } from '../../reducers';
import { setShowEditResubmitModal } from '../../store/claimResubmission.slice';
import { getTop, updateEncounters } from '../../store/procedureSignOffEncounter.slice';
import { largeModalStyle } from '../../utils/constants';
import ProcedureSignOffEncounterEdit from '../Assessments/ProcedureSignOff/ProcedureSignOffEncounterEdit';
import { useEffect, useState } from 'react';
import { statusValues } from '../../store/claimsManagement.slice';

const ClaimEditResubmission = (props) => {
    const dispatch = useDispatch();
    const { claimNumber, dateOfService, showEditResubmitModal, isResubmit } = useSelector((state: RootState) => state.claimResubmission);
    const { selectedMember } = useSelector((state: RootState) => state.memberDetail);
    const { draftEncounterList } = useSelector((state: RootState) => state.procedureSignOffEncounter);
    const [selectedStatus, setSelectedStatus] = useState(props.encounterStatus);

    useEffect(() => {
        console.log('props.encounterStatus', props.encounterStatus);
        setSelectedStatus(props.encounterStatus);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.encounterStatus]);

    const renderBody = () => {
        return (
            <Stack spacing={2}>
                <Grid container>
                    <Grid item xs>
                        <Paper elevation={3} style={{ padding: '8px', textAlign: 'left' }}>
                            <Divider style={{ marginBottom: '16px' }}>
                                <Chip label="Member" />
                            </Divider>
                            <Stack direction="row" spacing={2}>
                                <Stack spacing={2}>
                                    <Avatar
                                        alt={selectedMember?.FirstName + ' ' + selectedMember?.LastName}
                                        src={selectedMember?.ProfileImageUrl}
                                        sx={{ width: 144, height: 144 }}
                                        className="profile-image"
                                    />
                                </Stack>
                                <Stack style={{ width: '100%' }} spacing={2}>
                                    <FormGroup>
                                        <Stack direction="row" spacing={2}>
                                            <TextField aria-readonly="true" fullWidth label="First Name" variant="outlined" value={selectedMember?.FirstName} />
                                            <TextField
                                                aria-readonly="true"
                                                fullWidth
                                                label="Middle Name"
                                                variant="outlined"
                                                value={selectedMember?.MiddleName ? selectedMember?.MiddleName : ''}
                                            />
                                            <TextField aria-readonly="true" fullWidth label="Last Name" variant="outlined" value={selectedMember?.LastName} />
                                        </Stack>
                                    </FormGroup>
                                    <FormGroup>
                                        <Stack direction="row" spacing={2}>
                                            <TextField
                                                aria-readonly="true"
                                                fullWidth
                                                label="AHCCCS ID"
                                                variant="outlined"
                                                value={selectedMember?.Mem_AHCCCS_ID}
                                            />
                                            <TextField aria-readonly="true" fullWidth label="DOB" variant="outlined" value={selectedMember?.DOB} />
                                            <FormControl variant="standard" fullWidth>
                                                <InputLabel htmlFor="formatted-text-mask-input">SSN</InputLabel>
                                                <Input
                                                    disabled={true}
                                                    value={selectedMember?.SSN ? 'XXX-XX-' + selectedMember?.SSN.substring(selectedMember?.SSN.length - 4) : ''}
                                                />
                                            </FormControl>
                                        </Stack>
                                    </FormGroup>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                    <Grid item xs={4}>
                        <Paper elevation={3} style={{ padding: '8px', marginLeft: '8px', textAlign: 'left', minHeight: '208px' }}>
                            <Divider style={{ marginBottom: '16px' }}>
                                <Chip label="Claim Information" />
                            </Divider>
                            <Stack direction="row" spacing={2}>
                                <Stack style={{ width: '100%' }} spacing={2}>
                                    <FormGroup>
                                        <Stack spacing={2}>
                                            <TextField aria-readonly="true" fullWidth label="Claim Number" variant="outlined" value={claimNumber} />
                                            <TextField aria-readonly="true" fullWidth label="Date of Service" variant="outlined" value={dateOfService} />
                                        </Stack>
                                    </FormGroup>
                                </Stack>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
                {!isResubmit && (
                    <FormControl>
                        <InputLabel id="plan-type-label">Select Status</InputLabel>
                        <Select
                            labelId="plan-type-label"
                            id="plan-type"
                            value={selectedStatus}
                            margin="dense"
                            label="Select Status"
                            name="HealthPlanType"
                            onChange={(e) => {
                                setSelectedStatus(e.target.value);
                            }}
                        >
                            {statusValues
                                .filter((x) => x.value === 5 || x.value === 6)
                                .map((status) => {
                                    return (
                                        <MenuItem value={status.value} key={status.value}>
                                            {status.label}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </FormControl>
                )}
                <ProcedureSignOffEncounterEdit encounterStatus={props.encounterStatus} searchClaims={props.searchClaims} />
                <Box>
                    <Stack direction="row" spacing={2} style={{ float: 'right' }}>
                        <Button
                            className="button-120"
                            variant="contained"
                            onClick={() => {
                                dispatch(updateEncounters(draftEncounterList, isResubmit ? EncounterStatusEnum.ToSubmit : selectedStatus, claimNumber));
                            }}
                        >
                            {isResubmit ? 'Resubmit' : 'Save Claim'}
                        </Button>
                        <Button
                            className="button-120"
                            variant="outlined"
                            onClick={() => {
                                dispatch(setShowEditResubmitModal(false, false));
                            }}
                        >
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        );
    };

    return (
        <Modal
            open={showEditResubmitModal}
            style={{ overflow: 'scroll' }}
            onClose={() => {
                dispatch(setShowEditResubmitModal(false, true));
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={largeModalStyle} style={{ top: getTop(draftEncounterList) }}>
                <Stack spacing={2}>
                    <Typography variant="h5">{isResubmit ? 'Resubmit Claim' : 'Save Claim'}</Typography>
                    <Divider />
                    {renderBody()}
                </Stack>
            </Box>
        </Modal>
    );
};

export default ClaimEditResubmission;
