import { combineReducers } from 'redux';
import adminMembers from '../store/adminMembers.slice';
import adminOnboardingSlice from '../store/adminOnboarding.slice';
import adminProvider from '../store/adminProvider.slice';
import adminRoles from '../store/adminRoles.slice';
import adminRegions from '../store/adminRegions.slice';
import assessment from '../store/assessment.slice';
import assignEducationMaterials from '../store/assignEducationMaterials.slice';
import appointment from '../store/appointment.slice';
import appointmentMap from '../store/appointmentMap.slice';
import claimsManagement from '../store/claimsManagement.slice';
import encounterBillingHistoryReport from '../store/encounterBillingHistoryReport.slice';
import engagementsVsEncounters from '../store/engagementsVsEncounters.slice';
import generateEncounterBillingReport from '../store/generateEncounterReports.slice';
import hie from '../store/hie.slice';
import memberBirthdayReport from '../store/memberBirthdayReport.slice';
import memberDetail from '../store/memberDetail.slice';
import memberDetailsReport from '../store/memberDetailsReport.slice';
import memberDocuments from '../store/memberDocuments.slice';
import memberEncounterBillingReport from '../store/memberEncounterBillingReport.slice';
import members from '../store/members.slice';
import memberEligibilityReport from '../store/memberEligibilityReport.slice';
import memberStatusReport from '../store/memberStatusReport.slice';
import memberVitalSigns from '../store/memberVitalSigns.slice';
import onboardingMemberReport from '../store/onboardingMemberReport.slice';
import performanceDashboard from '../store/performanceDashboard.slice';
import providerLeave from '../store/providerLeave.slice';
import transitionalCare from '../store/transitionalCare.slice';
import facilities from '../store/facilities.slice';
import encounter from '../store/encounter.slice';
import documentEngagement from '../store/documentEngagement.slice';
import documentOnboarding from '../store/documentOnboarding.slice';
import carePlan from '../store/carePlan.slice';
import provider from '../store/provider.slice';
import adminHealthPlanSlice from '../store/adminHealthPlan.slice';
import remoteMonitoring from '../store/remoteMonitoring.slice';
import request from '../store/request.slice';
import requests from '../store/requests.slice';
import requestReport from '../store/requestReport.slice';
import sdohAssessmentsRequests from '../store/sdohAssessmentsRequests.slice';
import shared from '../store/shared.slice';
import smartDashboard from '../store/smartDashboard.slice';
import teamReport from '../store/teamReport.slice';
import transitionalCareReport from '../store/transitionalCareReport.slice';
import memberBudget from '../store/memberBudget.slice';
import medication from '../store/medication.slice';
import newRequest from '../store/newRequest.slice';
import hra from '../store/hra.slice';
import timeline from '../store/timeline.slice';
import clinicalData from '../store/clinicalData.slice';
import providerClaims from '../store/providerClaims.slice';
import claimsErrorReport from '../store/claimsErrorReport.slice';
import claimResubmission from '../store/claimResubmission.slice';
import memberEducation from '../store/memberEducation.slice';
import fuhfum from '../store/fuhfum.slice';
import procedureSignOffEncounter from '../store/procedureSignOffEncounter.slice';
import vitalSignsMemberDetail from '../store/vitalSignsMemberDetail.slice';
import providerNavbar from '../store/providerNavbar.slice';
import memberDemographicsDetails from '../store/memberDemographicsDetails.slice';
import authentication from '../store/authentication.slice';
import surveyReport from '../store/surveyReport.slice';
import notificationsPage from '../store/notificationsPage.slice';
import onboardingWorkflowSlice from '../store/onboardingWorkflow.slice';
import teams from '../store/teams.slice';
import officeSchedulerSlice from '../store/officeScheduler.slice';
import appointmentScheduler from '../store/appointmentScheduler.slice';
import googlePlace from '../store/googlePlace.slice';
import providerRotationSlice from '../store/providerRotation.slice';
import calendar from '../store/calendar.slice';

const rootReducer = combineReducers({
    adminHealthPlanSlice,
    adminMembers,
    adminOnboardingSlice,
    adminProvider,
    adminRegions,
    adminRoles,
    appointment,
    appointmentMap,
    appointmentScheduler,
    assessment,
    assignEducationMaterials,
    claimsManagement,
    encounterBillingHistoryReport,
    engagementsVsEncounters,
    facilities,
    generateEncounterBillingReport,
    hie,
    memberBirthdayReport,
    memberDetail,
    memberDetailsReport,
    memberDocuments,
    memberEligibilityReport,
    memberEncounterBillingReport,
    memberStatusReport,
    members,
    memberVitalSigns,
    notificationsPage,
    onboardingMemberReport,
    performanceDashboard,
    providerLeave,
    remoteMonitoring,
    transitionalCare,
    encounter,
    documentEngagement,
    documentOnboarding,
    carePlan,
    provider,
    providerRotationSlice,
    requestReport,
    sdohAssessmentsRequests,
    request,
    requests,
    shared,
    smartDashboard,
    teamReport,
    transitionalCareReport,
    vitalSignsMemberDetail,
    memberBudget,
    medication,
    hra,
    newRequest,
    timeline,
    clinicalData,
    providerClaims,
    claimsErrorReport,
    claimResubmission,
    memberEducation,
    fuhfum,
    procedureSignOffEncounter,
    providerNavbar,
    authentication,
    memberDemographicsDetails,
    surveyReport,
    onboardingWorkflowSlice,
    teams,
    officeSchedulerSlice,
    googlePlace,
    calendar,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
