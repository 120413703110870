import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { Button, Card, CardActions, CardContent, CardHeader, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowMessage } from '../../../store/shared.slice';
import { appointmentTypeEnums, meansOfEngagementEnums, parentUrl } from '../../../utils/constants';
import { appointmentStatusMap } from '../../../utils/mappings';
import phoneFormat from '../../../utils/phoneFormat';
import { formatAppointmentTime, formatDateMMDDYYYY } from '../../../utils/timeFormat';
import DeleteAppointmentConfirmationModal from '../Modals/DeleteAppointmentConfirmationModal';
import { deleteEvent, getEventTitle, sendSurvey } from '../Services/CommonCalendarServices';
import { CalendarEventData } from '../types/CalendarEventData';
import ProviderNameExpander from './ProviderNameExpander';
import TooltipDetailLine from './TooltipDetailLine';
import { RootState } from '../../../reducers';
import { setMemberIdForFetchedDefaultAddress } from '../../../store/calendar.slice';

interface PopoverProps {
    hoveredEvent: CalendarEventData | null;
    onMouseEnter: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    onMouseLeave: (isDialogOpen: boolean) => void;
    onDeleteEventSuccess?: (eventId: string) => void;
    closePopup: () => void;
    onEventEditClick: (eventData: CalendarEventData) => void;
    onEventRescheduleClick?: (eventData: CalendarEventData) => void;
    onEventCancelClick: (eventData: CalendarEventData) => void;
}

const EventTitle: React.FC<{ hoveredEvent: CalendarEventData }> = (props) => {
    const { hoveredEvent } = props;
    const cardTitle = getEventTitle(hoveredEvent);
    return (
        <Typography variant="h6" color="text.primary">
            {cardTitle}
        </Typography>
    );
};

const EventSubHeader: React.FC<{ hoveredEvent: CalendarEventData }> = (props) => {
    const { hoveredEvent } = props;
    const cardSubHeader = useMemo(() => {
        let subHeader = `${appointmentTypeEnums.find((x) => x.value === hoveredEvent.appointmentType)?.text ?? 'No Type'} - ${
            meansOfEngagementEnums.find((x) => x.value === hoveredEvent.meansOfEngagement)?.text ?? 'No Engagement Option'
        }`;
        return subHeader;
    }, [hoveredEvent]);

    return (
        <Stack>
            <Typography variant="body2" color="text.secondary">
                {hoveredEvent.MemberAHCCCSId ? `AHCCCS ID: ${hoveredEvent.MemberAHCCCSId}` : ``}
            </Typography>
            <Typography variant="body2" color="text.secondary">
                {cardSubHeader}
            </Typography>
        </Stack>
    );
};

const EventTooltip: React.FC<PopoverProps> = (props: PopoverProps) => {
    const { hoveredEvent, onMouseEnter, onMouseLeave, closePopup, onDeleteEventSuccess, onEventEditClick, onEventRescheduleClick, onEventCancelClick } = props;
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState<boolean>(false);
    const dispatch = useDispatch();
    const { defaultAddress } = useSelector((state: RootState) => state.calendar);
    const handleRescheduleEventClick = (eventData: CalendarEventData) => {
        onEventRescheduleClick?.(eventData);
        closePopup();
    };
    const handleSendSurveyButtonClick = useCallback(async () => {
        var response = await sendSurvey(hoveredEvent.Id);
        if (response.status === 200) {
            dispatch(setShowMessage(true, 'Survey sent successfully', 'success'));
        } else {
            dispatch(setShowMessage(true, 'Error sending survey', 'error'));
        }
    }, [dispatch, hoveredEvent.Id]);

    useEffect(() => {
        if (hoveredEvent?.MemberId) {
            dispatch(setMemberIdForFetchedDefaultAddress(hoveredEvent.MemberId));
        }
    }, [dispatch, hoveredEvent.MemberId]);

    return (
        <>
            <DeleteAppointmentConfirmationModal
                open={isDeleteConfirmationOpen}
                onClose={() => {
                    setIsDeleteConfirmationOpen(false);
                }}
                onSuccess={async () => {
                    try {
                        const response = await deleteEvent(hoveredEvent.Id);
                        if (response.status === 204) {
                            console.log('Successfully deleted appointment');
                            onDeleteEventSuccess?.(hoveredEvent.Id);
                        } else {
                            dispatch(setShowMessage(true, 'Error deleting appointment', 'error'));
                            console.error('Error deleting appointment');
                        }
                        setIsDeleteConfirmationOpen(false);
                        closePopup();
                    } catch (error) {
                        let errorMessage = 'An error occurred while deleting the appointment.';
                        if (error?.response?.data) {
                            errorMessage = `${error}. \n\nReason: ${error.response.data}`;
                        }
                        dispatch(setShowMessage(true, errorMessage, 'error'));
                        console.error('Error deleting appointment', error);
                    }
                }}
            />
            <Card
                onMouseEnter={onMouseEnter}
                onMouseLeave={() => onMouseLeave(isDeleteConfirmationOpen)}
                sx={{ width: '400px' }} // Adjust the width value as needed
            >
                <CardHeader
                    sx={{ backgroundColor: hoveredEvent.color }}
                    title={<EventTitle hoveredEvent={hoveredEvent} />}
                    action={
                        <Tooltip placement="top" title="Close popup">
                            <IconButton size="small" onClick={closePopup}>
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    subheader={<EventSubHeader hoveredEvent={hoveredEvent} />}
                />
                <CardContent>
                    <Stack spacing={1}>
                        <ProviderNameExpander providerNames={hoveredEvent.providerNames} />
                        <TooltipDetailLine
                            hide={
                                hoveredEvent.startDate === null ||
                                hoveredEvent.startDate === undefined ||
                                hoveredEvent.endDate === null ||
                                hoveredEvent.endDate === undefined
                            }
                            title="Time"
                            details={`${formatDateMMDDYYYY(new Date(hoveredEvent.start))} @ ${formatAppointmentTime(
                                new Date(hoveredEvent.startDate)
                            )} - ${formatAppointmentTime(new Date(hoveredEvent.endDate))}`}
                        />
                        {<TooltipDetailLine hide={!hoveredEvent?.Phone} title="Phone" details={`${phoneFormat(hoveredEvent.Phone)}`} />}
                        <TooltipDetailLine
                            hide={
                                hoveredEvent.CustomAddress === null ||
                                hoveredEvent.CustomAddress === undefined ||
                                hoveredEvent.CustomAddress === '' ||
                                !hoveredEvent.IsCustomAddress
                            }
                            title="Custom Address"
                            details={hoveredEvent.CustomAddress}
                        />
                        <TooltipDetailLine
                            hide={
                                hoveredEvent.address === null ||
                                hoveredEvent.address === undefined ||
                                hoveredEvent.address === '' ||
                                hoveredEvent.IsCustomAddress
                            }
                            title="Address"
                            details={defaultAddress ?? hoveredEvent.address}
                        />
                        <Typography
                            hidden={!hoveredEvent?.reason}
                            style={{ whiteSpace: 'pre-wrap', overflowWrap: 'break-word', overflowY: 'auto', maxHeight: 200 }}
                        >
                            <span style={{ fontWeight: 'bold' }}>Notes: </span>
                            {hoveredEvent.reason ?? 'No Notes'}
                        </Typography>
                    </Stack>
                </CardContent>
                <CardActions>
                    <Button onClick={handleSendSurveyButtonClick} variant="contained" size="small" startIcon={<QuestionAnswerIcon />}>
                        Send Survey
                    </Button>
                    <Tooltip title="Edit Appointment">
                        <IconButton onClick={() => onEventEditClick(hoveredEvent)}>
                            <EditIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Cancel Appointment">
                        <IconButton
                            hidden={
                                ![appointmentStatusMap.Unconfirmed.value, appointmentStatusMap.Confirmed.value].includes(hoveredEvent.status) ||
                                hoveredEvent.RescheduledAppointmentId?.length > 0
                            }
                            onClick={() => {
                                onEventCancelClick(hoveredEvent);
                                closePopup();
                            }}
                        >
                            <EventBusyIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Reschedule Appointment">
                        <IconButton
                            disabled={!!!onEventRescheduleClick}
                            hidden={hoveredEvent.RescheduledAppointmentId?.length > 0}
                            onClick={() => {
                                handleRescheduleEventClick(hoveredEvent);
                            }}
                        >
                            <EventRepeatIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Go to member demographics page">
                        <IconButton
                            hidden={hoveredEvent?.MemberObjectId === '' || hoveredEvent?.MemberObjectId === null || hoveredEvent?.MemberObjectId === undefined}
                            onClick={() => {
                                window.open(`${parentUrl}provider/members-list/member-chart/${hoveredEvent?.MemberObjectId}/scheduling`, '_blank');
                            }}
                        >
                            <OpenInNewIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete Appointment">
                        <IconButton onClick={() => setIsDeleteConfirmationOpen(true)}>
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip>
                </CardActions>
            </Card>
        </>
    );
};

export default EventTooltip;
